import { Button, Grid, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';

import DevelopedBySection from '../../../../../components/LayoutAuth/DevelopedBySection';
import HeaderLogo from '../../../../../components/LayoutAuth/HeaderLogo';
import HelpSection from '../../../../../components/LayoutAuth/HelpSection';
import { useStyles as useStylesLogin } from '../../../../../styles/global/stylesLogin';
import { useStyles } from './styles';

const TokenNotValidForm = ({ infoText, buttonText, buttonPathName }: TokenNotValidFormProps) => {
  const classes = useStyles();
  const classesLogin = useStylesLogin();
  const history = useHistory();

  const goToRequestAccount = () => {
    history.push({ pathname: buttonPathName });
  };

  return (
    <>
      <HeaderLogo />

      <Grid className={classes.containerTitle}>
        <Typography className={classesLogin.title}>{'Tu token ya no es válido'}</Typography>
        <Typography className={classes.subTitle}>{infoText}</Typography>

        <Button
          className={classesLogin.button}
          variant="contained"
          size="medium"
          style={{ textTransform: 'none' }}
          onClick={goToRequestAccount}
        >
          {buttonText}
        </Button>
      </Grid>

      <HelpSection />

      <DevelopedBySection />
    </>
  );
};

export interface TokenNotValidFormProps {
  infoText: string;
  buttonText: string;
  buttonPathName: string;
}

export default TokenNotValidForm;
