import Container from '../../../../components/Containers/Container';
import ContainerPage from '../../../../components/LayoutAuth/ContainerPage';
import TokenNotValidForm, { TokenNotValidFormProps } from './TokenNotValidForm';

const TokenNotValid = ({ infoText, buttonText, buttonPathName }: TokenNotValidFormProps) => {
  return (
    <Container>
      <ContainerPage>
        <TokenNotValidForm
          infoText={infoText}
          buttonText={buttonText}
          buttonPathName={buttonPathName}
        />
      </ContainerPage>
    </Container>
  );
};

export default TokenNotValid;
