import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';

import { useStyles } from './styles';

interface KeyValue {
  key: string;
  value: string;
}

interface StatusCardProps {
  id: number;
  title: string;
  status: string;
  columnA: KeyValue[];
  columnB: KeyValue[];
  onTrackClick: any;
}

const ServiceCard: React.FC<StatusCardProps> = (props) => {
  const theme = useTheme();
  const classes = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { id, title, status, columnA, columnB, onTrackClick } = props;
  return (
    <Card className={classes.card}>
      {/* Header */}
      <CardHeader
        className={classes.cardHeader}
        title={<Typography className={classes.title}>{title}</Typography>}
        action={<Chip label={status} className={classes.chip} />}
      />
      <hr className={classes.line}></hr>
      {/* Body */}
      <CardContent>
        <Grid container spacing={2} className={classes.body}>
          {isMobile ? (
            // Single column for mobile
            <>
              {columnA.map((item, index) => (
                <Grid item xs={12} key={`a-${index}`} className={classes.item}>
                  <Grid key={index} style={{ display: 'flex' }}>
                    <Typography className={classes.paragraphKey}>{item.key}: </Typography>
                    <Typography className={classes.paragraphValue}>{item.value}</Typography>
                  </Grid>
                </Grid>
              ))}
              {columnB.map((item, index) => (
                <Grid item xs={12} key={`b-${index}`} className={classes.item}>
                  <Grid key={index} style={{ display: 'flex' }}>
                    <Typography className={classes.paragraphKey}>{item.key}: </Typography>
                    <Typography className={classes.paragraphValue}>{item.value}</Typography>
                  </Grid>
                </Grid>
              ))}
            </>
          ) : (
            // Two columns for larger screens
            <>
              <Grid item xs={6} className={classes.item}>
                {columnA.map((item, index) => (
                  <Grid key={index} style={{ display: 'flex' }}>
                    <Typography className={classes.paragraphKey}>{item.key}: </Typography>
                    <Typography className={classes.paragraphValue}>{item.value}</Typography>
                  </Grid>
                ))}
              </Grid>

              <Grid item xs={6} className={classes.item}>
                {columnB.map((item, index) => (
                  <Grid key={index} style={{ display: 'flex' }}>
                    <Typography className={classes.paragraphKey}>{item.key}: </Typography>
                    <Typography className={classes.paragraphValue}>{item.value}</Typography>
                  </Grid>
                ))}
              </Grid>
            </>
          )}
        </Grid>
      </CardContent>
      <hr className={classes.line}></hr>
      {/* Footer */}
      <CardActions style={{ justifyContent: 'center', padding: '12px 16px' }}>
        <Button
          variant="text"
          style={{ textTransform: 'none' }}
          className={classes.button}
          onClick={() => onTrackClick(id)}
        >
          Ver seguimiento
        </Button>
      </CardActions>
    </Card>
  );
};

export default ServiceCard;
