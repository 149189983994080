import axios from 'axios';

import { getTokenAuthentication } from '../util/commons';
import { getSessionInfo } from '../util/LocalStorage/login';

export const getTrackingByBooking = (businessUnitUUID: string | undefined, bookingId: any) => {
  const headers = { headers: { businessUnitUUID: businessUnitUUID } };
  return axios
    .get(
      `${process.env.REACT_APP_URL_ECOMMERCE_CUSTOMER_PORTAL}/front-customer/${bookingId}/private/tracking-link`,
      headers
    )
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

export const getFantasyServiceNames = (url: string, serviceId: any) => {
  //TODO
  const payload = {
    ids: [serviceId],
  };

  return axios
    .post(
      `${process.env.REACT_APP_URL_ECOMMERCE_CUSTOMER_PORTAL}/front-customer/get-fantasy-service-names?url=${url}`,
      payload
    )
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};

export const getBookingsBycustomerId = (
  businessunituuid: any,
  limit: number,
  page: any,
  statusActive: boolean,
  url: any
) => {
  const isAuthenticated = getSessionInfo(businessunituuid);
  const token = isAuthenticated.token;

  const headers = { headers: { businessunituuid, token: token } };

  return axios
    .get(
      `${process.env.REACT_APP_URL_ECOMMERCE_CUSTOMER_PORTAL}/front-customer/booking/private/by-customer-for-my-asists?page=${page}&limit=${limit}&statusActive=${statusActive}&url=${url}`,
      headers
    )
    .then((res) => res.data)
    .catch((err) => {
      throw err;
    });
};
