import { Grid, Typography } from '@mui/material';
import { memo, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ContainerAuthPage from '../../../components/Containers/ContainerAuthPage/ContainerAuthPage';
import TabComponent from '../../../components/TabComponent';
import ServiceCardList from './ServiceCardList';
import { useStyles } from './styles';

const MyAssists = (props: any) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [tabs, setTabs] = useState([]);
  const initialIndexTab = 0;

  const ActiveServicesTab = memo((props: any) => <ServiceCardList status={true} {...props} />);
  ActiveServicesTab.displayName = 'ActiveServicesTab';
  const FinishedServicesTab = memo((props: any) => <ServiceCardList status={false} {...props} />);
  FinishedServicesTab.displayName = 'FinishedServicesTab';

  const handlerLoadServiceTabs = () => {
    const tabsToAdd: any = [
      {
        name: 'ACTIVAS',
        component: ActiveServicesTab,
      },
      {
        name: 'FINALIZADAS',
        component: FinishedServicesTab,
      },
    ];
    setTabs(tabsToAdd);
  };
  useEffect(() => {
    handlerLoadServiceTabs();
  }, []);

  return (
    <div className={classes.root}>
      <Typography className={classes.title} color="textPrimary">
        Mis asistencias
      </Typography>

      <TabComponent
        initialIndexTab={initialIndexTab}
        headers={tabs}
        content={tabs.map((tab: any, tabId) => {
          const TabComponent = tab.component;
          return TabComponent ? (
            <TabComponent
              key={tabId}
              navigationPortal={props?.onNavigationPortal}
              {...props}
              {...tab.data}
            />
          ) : null;
        })}
        onChange={undefined}
      />
    </div>
  );
};

export default MyAssists;
