import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { hexToRGBA } from '../../util/commons';

export const useStyles = makeStyles((theme: Theme) => ({
  header: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.4px',
    alignContent: 'center',
    width: '100%',
    '& .MuiTabs-indicator': {
      color: `${theme.palette.primary.main} !important`,
      backgroundColor: `${theme.palette.primary.main} !important`,
    },
    '& .MuiTab-root.Mui-selected': {
      borderBottom: `2px solid ${hexToRGBA(theme.palette.primary.main, 0.12)} !important`,
      color: `${theme.palette.primary.main} !important`,
      height: '78px',
    },
    '& .MuiTab-root': {
      borderBottom: '2px solid rgba(0, 0, 0, 0.12)',
    },
  },
  headerLabel: {
    '& .MuiTabs-selected': {
      borderBottom: `2px solid ${hexToRGBA(theme.palette.primary.main, 0.12)} !important`,
      color: `${theme.palette.primary.main} !important`,
    },
  },
  content: {
    marginTop: '20px',
    width: '100%',
  },
}));
