import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { hexToRGBA } from '../../../../util/commons';

export const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontFamily: 'Roboto !important',
    fontSize: '16px !important',
    fontWeight: '400 !important',
    lineHeight: '28px !important',
    letterSpacing: '0.15000000596046448px !important',
  },
  card: {
    borderRadius: '20px !important',
    width: 'inherit',
    padding: '0px',
    margin: '0px 20px 0px 0px',
    [theme.breakpoints.down('md')]: {
      margin: '12px 0px 0px 0px',
    },
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '13px 16px !important',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column-reverse',
      '& .MuiCardHeader-action': {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
      },
      '& .MuiCardHeader-content': {
        width: '100% !important',
      },
    },
  },
  line: {
    border: '1px solid rgba(0, 0, 0, 0.09)',
    margin: 0,
  },
  chip: {
    fontFamily: 'Roboto !important',
    fontSize: '13px !important',
    fontWeight: '400 !important',
    backgroundColor: `${hexToRGBA(theme.palette.primary.main, 0.08)} !important`,
    color: `${theme.palette.primary.main} !important`,
    height: '24px',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
  paragraphKey: {
    fontFamily: 'Roboto !important',
    fontSize: '14px !important',
    fontWeight: '400 !important',
    lineHeight: '20.02px !important',
    letterSpacing: '0.15000000596046448px !important',
    width: '120px',
  },
  paragraphValue: {
    fontFamily: 'Roboto !important',
    fontSize: '14px !important',
    fontWeight: '400 !important',
    lineHeight: '20.02px !important',
    letterSpacing: '0.15000000596046448px !important',
  },
  button: {
    fontFamily: 'Roboto !important',
    fontSize: '14px !important',
    fontWeight: '500 !important',
    lineHeight: '20.02px !important',
    letterSpacing: '0.15000000596046448px !important',
    color: `${theme.palette.primary.main} !important`,
  },
  body: {
    margin: '12px 0px !important',
  },
  item: {
    padding: '0px 0px !important',
    [theme.breakpoints.down('lg')]: {
      padding: '0px !important',
    },
  },
}));
