import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '25px 32px !important',
    background: '#FAFAFA !important',
    minHeight: '100vh',
    [theme.breakpoints.down('sm')]: {
      padding: '25px 16px !important',
    },
  },
  title: {
    fontFamily: 'Roboto !important',
    fontSize: '18px !important',
    fontWeight: '500 !important',
    lineHeight: '28.8px !important',
    letterSpacing: '0.15000000596046448px !important',
    color: 'rgba(37, 37, 37, 1)',
  },
  cardsContainer: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      gap: '10px',
    },
  },
  container: {
    display: 'flex !important',
    justifyContent: 'center !important',
  },
}));
