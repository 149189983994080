import { Box, Tab, Tabs, useTheme } from '@mui/material';
import { FC, useState } from 'react';
import React from 'react';

import { useStyles } from './styles';

const TabComponent: FC<TabComponentProps> = (props) => {
  const classes = useStyles();
  const { headers = [], content = [], initialIndexTab = 0, onChange } = props;
  const [control, setControl] = useState({ index: initialIndexTab });
  const theme = useTheme();
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ width: '100%', display: 'flex' }}>
        {headers.length >= 1 && (
          <Tabs
            className={classes.header}
            value={control.index}
            variant={'fullWidth'}
            indicatorColor={'primary'}
            textColor={'primary'}
            onChange={(event: any, value: any) => {
              setControl({ ...control, index: value });
              onChange && onChange(value); // Llamando al callback.
            }}
          >
            {headers &&
              headers.map((header: any, index: any) => (
                <Tab
                  key={index}
                  className={classes.headerLabel}
                  label={header.name}
                  disabled={header.enable !== undefined ? !header.enable : false}
                />
              ))}
          </Tabs>
        )}
      </Box>

      {content &&
        !!content.length &&
        content.map((tab: any, it: any) => (
          <React.Fragment key={it}>
            <Box className={classes.content}>{control.index === it ? tab : null}</Box>
          </React.Fragment>
        ))}
    </Box>
  );
};

interface TabComponentProps {
  initialIndexTab: number;
  headers: never[];
  content: any;
  onChange: any;
}

export default TabComponent;
