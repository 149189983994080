import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FC, useContext } from 'react';

import CustomAccordion from '../../../../../../components/CustomAccordion/CustomAccordion';
import BusinessUnitParamsContext from '../../../../../../contexts/BusinessUnitParamsContext/businessUnitParamsContext';
import { ServiceData } from '../../../../../../models/ServiceDataModel';
import { isAuthenticate } from '../../../../../../util/commons';
import { AccordionActions } from '../../AccordionActions/AccordionActions';
import { ScheduledServiceStepContentAuth } from './ScheduledServiceStepContentAuth/ScheduledServiceStepContentAuth';

type Props = {
  serviceData: ServiceData | undefined;
  showDateProfessional?: boolean;
  showStarDate?: boolean;
  showEndServiceDate?: boolean;
  cardTitle?: string | undefined;
};

export const ScheduledServiceStepAuth: FC<Props> = ({ serviceData, cardTitle }) => {
  const { businessUnitUUID } = useContext(BusinessUnitParamsContext);
  const isAuthenticateUser: any = isAuthenticate(businessUnitUUID);
  const idProfessional = serviceData ? serviceData.idProfessional : null;
  const idProvider = serviceData ? serviceData.idProvider : null;

  const getActionsAccordion = () => {
    return <AccordionActions />;
  };

  return (
    <CustomAccordion
      expandedDefault={isAuthenticateUser}
      expandIcon={isAuthenticateUser ? <ExpandMoreIcon /> : null}
      title={cardTitle ? cardTitle : 'Información del servicio'}
      detailsChildren={
        <ScheduledServiceStepContentAuth
          idProfessional={idProfessional}
          idProvider={idProvider}
          serviceData={serviceData}
        />
      }
      // actionsChildren={getActionsAccordion()}
    />
  );
};
