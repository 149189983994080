import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { hexToRGBA } from '../../../../util/commons';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    height: '100vh',
    gap: '20px',
    [theme.breakpoints.down('sm')]: {
      height: '100%',
    },
    [theme.breakpoints.between('md', 'lg')]: {
      height: '95vh',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      height: '70vh',
    },
  },
  rootV2: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    minHeight: '670px',
    gap: '20px',
    [theme.breakpoints.down('md')]: {
      gap: '10px',
    },
    [theme.breakpoints.between('md', 'lg')]: {
      height: '95vh',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      height: '70vh',
    },
  },
  cardsContainerEmpty: {
    minHeight: '0px',
  },
  title: {
    fontFamily: 'Roboto !important',
    fontSize: '18px !important',
    fontWeight: '500 !important',
    lineHeight: '28.8px !important',
    letterSpacing: '0.15000000596046448px !important',
    color: 'rgba(37, 37, 37, 1)',
    [theme.breakpoints.down('md')]: {
      fontSize: '16px !important',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px !important',
    },
  },
  cardsContainer: {
    display: 'grid !important',
    [theme.breakpoints.down('sm')]: {
      gap: '10px',
    },
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridGap: '20px',
    padding: '0px 12px',
    alignContent: 'space-between',
    minHeight: '670px',
  },
  container: {
    padding: '0px 40px',
    marginTop: '-70px',
    [theme.breakpoints.down('sm')]: {
      marginTop: '-18px',
      padding: '0rem 1rem !important',
    },
  },
  cardsInternContainer: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      gap: '10px',
    },
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  cardsContainerDesktop: {
    display: 'flex !important',
    [theme.breakpoints.down('sm')]: {
      gap: '10px',
    },
  },
  pagination: {
    fontFamily: 'Roboto !important',
    fontSize: '14px !important',
    fontWeight: '400 !important',
    lineHeight: '28.8px !important',
    letterSpacing: '0.15000000596046448px !important',
    color: `${theme.palette.primary.main} !important`,
    '& .Mui-selected': {
      backgroundColor: `${hexToRGBA(theme.palette.primary.main, 0.04)} !important`,
      color: `${theme.palette.primary.main} !important`,
      fontSize: '14px !important',
      fontWeight: '400 !important',
      lineHeight: '28.8px !important',
      letterSpacing: '0.15000000596046448px !important',
      '&:hover': {
        color: `${theme.palette.primary.main} !important`,
      },
    },
    '& .MuiPaginationItem-icon': {
      '&:hover': {
        color: `${theme.palette.primary.main} !important`,
      },
      '&:active': {
        color: `${theme.palette.primary.main} !important`,
      },
    },
    '& .MuiPaginationItem-root': {
      '&:hover': {
        color: `${theme.palette.primary.main} !important`,
      },
    },

    [theme.breakpoints.down('md')]: {
      '& .MuiPagination-ul': {
        display: 'flex !important',
        justifyContent: 'center !important',
        alignItems: 'center !important',
      },
      '& .MuiPaginationItem-root !important': {
        minWidth: 'auto !important',
        padding: '0 8px !important',
      },
      '& .MuiPaginationItem-page': {
        display: 'none !important',
      },
      '& .MuiPaginationItem-ellipsis': {
        display: 'none !important',
      },
      '& .Mui-selected': {
        display: 'block !important',
      },
    },
  },
  paginationCard: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '16px 120px',
    marginTop: '0px',
    borderRadius: '10px !important',
    [theme.breakpoints.down('md')]: {
      padding: '16px',
      justifyContent: 'center',
    },
    overflow: 'visible !important',
  },
  emptyCard: {
    display: 'flex !important',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'center !important',
    alignItems: 'center',
  },
  emptyCardTitle: {
    color: `${theme.palette.primary.main} !important`,
    fontFeatureSettings: "'liga' off, 'clig' off",
    fontFamily: 'Roboto !important',
    fontSize: '22px !important',
    fontStyle: 'normal !important',
    fontWeight: '400 !important',
    lineHeight: '133.4% !important',
    paddingBottom: '16px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px !important',
    },
  },
  emptyCardDescription: {
    maxWidth: '368px',
    textAlign: 'center',
    fontFeatureSettings: "'liga' off, 'clig' off",
    fontFamily: 'Roboto !important',
    fontSize: '14px !important',
    fontStyle: 'normal !important',
    fontWeight: '400 !important',
    lineHeight: '143% !important',
  },
  contact: {
    color: `${theme.palette.primary.main}`,
  },
  overlay: {
    maxHeight: '62vh !important',
    alignContent: 'center',
  },
  emptyCardcontainer: {
    padding: '60px 0px',
    display: 'flex !important',
    alignItems: 'center !important',
    justifyContent: 'flex-start',
    [(theme.breakpoints.down('sm'), theme.breakpoints.down('md'))]: {
      padding: '40px 0px',
      display: 'flex !important',
      justifyContent: 'center',
    },
    [theme.breakpoints.up('md')]: {
      justifyContent: 'center',
    },
  },
  cardContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '16px',
    marginTop: '20px',
    borderRadius: '20px !important',
  },
  img: {
    maxWidth: '336px',
    maxHeight: '285px',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '343px',
      maxHeight: '319px',
      width: '100%',
    },
  },
  imgContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginRight: '80px !important',
    padding: '0px 68px 0 182px',
    [(theme.breakpoints.down('sm'), theme.breakpoints.down('md'))]: {
      padding: '0px !important',
      marginRight: '0px !important',
    },
    [theme.breakpoints.up('md')]: {
      padding: '0px !important',
      marginRight: '80px !important',
    },
  },
}));
