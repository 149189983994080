import { Card, Grid, Pagination, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import moment from 'moment';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import { getBookingsBycustomerId } from '../../../../api/serviceTracking';
import Spinner from '../../../../components/Spinner';
import { EMPTY_IMAGE } from '../../../../constants/constants';
import BusinessUnitParamsContext from '../../../../contexts/BusinessUnitParamsContext/businessUnitParamsContext';
import { handleContactPhoneNumber } from '../../../../util/commons';
import ServiceCard from '../ServiceCard';
import { useStyles } from './styles';

const ServiceCardList = (props: any) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(0);
  const [total, setTotal] = useState(0);
  const history = useHistory();
  const { contextUrlHome, businessUnitUUID, dateFormatCode, timeFormat, timezoneIdName } =
    useContext(BusinessUnitParamsContext);
  const { portalConfigParams } = useContext(BusinessUnitParamsContext);
  const { contact_phone } = portalConfigParams!;
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const { status } = props;
  const timeFormatDate =
    timeFormat === '12hr' ? `hh:mma` : timeFormat === '24hr' ? `HH:mm` : dateFormatCode;
  const handleChange = async (event: React.ChangeEvent<unknown>, value: any) => {
    setPage(value);
    setLoading(true);
    await getData(value, limit);
  };

  const calculateLimit = (isMobile: boolean, isTablet: boolean) => {
    if (isMobile || isTablet) {
      return 2;
    }
    return 6;
  };

  useEffect(() => {
    const newLimit = calculateLimit(isMobile, isTablet);
    if (newLimit !== limit) {
      setLimit(newLimit);
    }
    if (page != 0) {
      getData(page, newLimit);
    }
  }, [isMobile, isTablet, limit]);

  useEffect(() => {
    if (page == 0) {
      setPage(1);
    }
  }, []);

  const getDateKeyValue = (item: any) => {
    return getKeyValue(item, 'date', 'DD/MM/yyyy');
  };

  const getHourKeyValue = (item: any) => {
    return getKeyValue(item, 'hour', timeFormatDate);
  };

  const getKeyValue = (item: any, type: 'date' | 'hour', format: any) => {
    const dateFields = [
      { field: 'acceptedDate', key: type === 'date' ? 'Fecha estimada' : 'Hora estimada' },
      { field: 'scheduledDate', key: type === 'date' ? 'Fecha deseada' : 'Hora deseada' },
      { field: 'desiredDate', key: type === 'date' ? 'Fecha deseada' : 'Hora deseada' },
      { field: 'onWayDate', key: type === 'date' ? 'Fecha estimada' : 'Hora estimada' },
      { field: 'startedDate', key: type === 'date' ? 'Fecha inicio' : 'Hora inicio' },
      { field: 'finishedDate', key: type === 'date' ? 'Fecha finalizado' : 'Hora finalizado' },
      { field: 'canceledDate', key: type === 'date' ? 'Fecha cancelado' : 'Hora cancelado' },
    ];
    for (const { field, key } of dateFields) {
      if (item[field]) {
        const parsedDate = parseDate(item[field]);
        if (parsedDate) {
          return {
            key,
            value: parsedDate.tz(timezoneIdName || '').format(format),
          };
        }
      }
    }

    return {};
  };

  const parseDate = (date: string) => {
    const formats = [
      'YYYY-MM-DDTHH:mm:ssZ',
      'YYYY-MM-DD HH:mm:ss',
      'YYYY/MM/DD HH:mm:ss',
      'YYYY-MM-DD',
      'YYYY/MM/DD',
    ];

    for (const format of formats) {
      const momentDate = moment.utc(date, format, true); // true asegura que se valide estrictamente el formato
      if (momentDate.isValid()) {
        return momentDate;
      }
    }
  };
  const getData = async (page: number, limit: number) => {
    try {
      await getBookingsBycustomerId(
        businessUnitUUID,
        limit,
        page,
        props.status,
        contextUrlHome
      ).then((res) => {
        if (res) {
          const items: any = [];
          res.items.map((item: any) => {
            items.push({
              id: item.id,
              status: item.state,
              name: item.serviceName,
              columnA: [
                {
                  key: 'Id Servicio',
                  value: '#' + item.id,
                },
                getDateKeyValue(item),
              ],
              columnB: [
                {
                  key: 'No. Caso',
                  value: '#' + item.caseId,
                },
                getHourKeyValue(item),
              ],
            });
          });
          setItems(items);
          if ((isMobile || isTablet) && res.extra.total / 2 > 1) {
            setTotal(Math.ceil(res.extra.total / 2));
          } else if (!(isMobile || isTablet) && res.extra.total / 6 > 1) {
            setTotal(Math.ceil(res.extra.total / 6));
          } else {
            setTotal(0);
          }
          setLoading(false);
        }
      });
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const handleTrackingBtnOnChange = (event: React.ChangeEvent<unknown>) => {
    history.push(`/validate-booking/${event}`);
  };
  if (loading)
    return (
      <Grid container className={classes.overlay}>
        <Spinner />
      </Grid>
    );
  return (
    <div className={items.length > 0 ? (items.length == 1 ? classes.rootV2 : classes.root) : ''}>
      <Grid
        container
        className={
          items.length == 0
            ? classes.cardsContainerEmpty
            : isMobile || isTablet
            ? classes.cardsContainerDesktop
            : classes.cardsContainer
        }
      >
        {items.map((item: any, index: number) => {
          return (
            <ServiceCard
              key={index}
              id={item.id}
              title={item.name}
              status={item.status}
              columnB={item.columnB}
              columnA={item.columnA}
              onTrackClick={(event: any) => handleTrackingBtnOnChange(event)}
            />
          );
        })}
      </Grid>
      {items.length == 0 && (
        <Card className={classes.cardContainer}>
          <Grid container className={classes.emptyCardcontainer}>
            <Grid item className={classes.imgContainer} flexDirection={'column'}>
              <img className={classes.img} src={EMPTY_IMAGE} alt={'empty-image'} />
            </Grid>
            <Grid item className={classes.emptyCard} flexDirection={'column'}>
              <Typography className={classes.emptyCardTitle}>
                {status
                  ? 'No hay asistencias activas en este momento.'
                  : 'Aún no tienes asistencias finalizadas.'}
              </Typography>
              {contact_phone && (
                <Grid container>
                  <Typography className={classes.emptyCardDescription}>
                    Para cualquier consulta, puedes comunicarte al número de contacto{' '}
                    <a className={classes.contact}>
                      {`${handleContactPhoneNumber(contact_phone)}`}
                    </a>
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Card>
      )}
      {total > 0 && (
        <Card className={classes.paginationCard}>
          <Stack spacing={2}>
            <Pagination
              page={page}
              defaultPage={1}
              count={total}
              size="small"
              className={classes.pagination}
              onChange={handleChange}
              color={'primary'}
              siblingCount={0}
              boundaryCount={0}
              showFirstButton={false}
              showLastButton={false}
            ></Pagination>
          </Stack>
        </Card>
      )}
    </div>
  );
};

export default ServiceCardList;
